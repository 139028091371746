<template>
  <div>
    <Modal :options="{width: '40vw'}" @close="$emit('close')">
      <form @submit.prevent="guardar">
        <div class="title">{{ !etapa.id ? 'Nueva etapa' : 'Editar etapa' }}</div>
        <div class="body">
          <div class="row form-group">
            <label for="etapa.etapa" class="col-form-label col-sm-3">Etapa</label>
            <div class="col-sm-9"><input v-model="etapa_nueva.etapa" type="text" name="etapa.etapa" id="etapa.etapa" class="form-control" autocomplete="off"></div>
          </div>
          <div class="row form-group">
            <label for="etapa.codigo" class="col-form-label col-sm-3">Código</label>
            <div class="col-sm-9"><input v-model="etapa_nueva.codigo" type="text" name="etapa.codigo" id="etapa.codigo" class="form-control" autocomplete="off"></div>
          </div>
          <div class="row form-group">
            <label for="etapa.descripcion" class="col-form-label col-sm-3">Descripción</label>
            <div class="col-sm-9"><input v-model="etapa_nueva.descripcion" type="text" name="etapa.descripcion" id="etapa.descripcion" class="form-control" autocomplete="off"></div>
          </div>
          <div class="row form-group">
            <label for="etapa.posicion" class="col-form-label col-sm-3">Despues de</label>
            <div class="col-sm-9">
              <select v-model="etapa_nueva.posicion" name="etapa.posicion" id="etapa.posicion" class="form-control">
                <option v-for="(etapa, index) in reordenar_etapas" :key="index" :value="etapa.posicion">{{ etapa.etapa }}</option>
              </select>
            </div>
          </div>
          <div class="row form-group">
            <label for="etapa.tipo_usuario" class="col-form-label col-sm-3">Tipo usuario</label>
            <div class="col-sm-9">
              <select v-model="etapa_nueva.tipo_usuario" name="etapa.tipo_usuario" id="etapa.tipo_usuario" class="form-control">
                <option value="0">Sistema</option>
                <option value="1">Valuador</option>
                <option value="2">Notario</option>
              </select>
            </div>
          </div>
          <div class="row form-group">
            <label for="etapa.etapa_final" class="col-form-label col-sm-3">Etapa final</label>
            <div class="col-sm-9">
              <select v-model="etapa_nueva.etapa_final" name="etapa.etapa_final" id="etapa.etapa_final" class="form-control">
                <option value="1">Sí</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <button class="btn btn-primary mr-2">Guardar</button>
              <button class="btn btn-danger" type="button" @click="$emit('close')">Cancelar</button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  import apiEtapa from '@/apps/formalizacion/api/v2/etapas'

  export default {
    components: {
      Modal
    }
    ,props: {
      etapa: {
        type: Object
        ,default() {
          return {
            id: null
          }
        }
      }
      ,etapas: {
        type: Array
        ,default() {
          return []
        }
      }
      ,tipo: 0
    }
    ,data() {
      return {
        etapa_nueva: {
          etapa: null
          ,codigo: null
          ,descripcion: null
          ,posicion: 1
          ,tipo_usuario: 1
          ,etapa_final: 0
          ,estatus: 1
        }
        ,etapas_orden: []
        ,etapa_tipo_usuario_org: null
      }
    }
    ,mounted() {
      if (this.etapa.etapa)
        this.etapa_nueva = this.$helper.clone(this.etapa);
      
      this.etapa_nueva.tipo_usuario = this.tipo;

      if (!this.etapa_nueva.posicion)
        this.etapa_nueva.posicion = this.reordenar_etapas[this.reordenar_etapas.length-1].posicion;
      else
        this.etapa_nueva.posicion -= 1;

      this.etapa_tipo_usuario_org = this.etapa_nueva.tipo_usuario;
    }
    ,methods: {
      guardar() {
        let errores = []

        if (!this.etapa.etapa)
          errores.push('Debes defininir una etapa');

        if (!this.etapa.codigo)
          errores.push('Debes definir el código');

        if (!this.etapa.descripcion)
          errores.push('Debes definir la descrición');

        if (this.etapa_tipo_usuario_org && this.etapa_tipo_usuario_org != this.etapa_nueva.tipo_usuario)
          this.$helper.showMessage('Advertencia','El tipo de usuario es diferente, se eliminó del tipo actual y se moverá al nuevo','warn','alert');

        this.$emit('nEtapa',this.etapa_nueva);
      }
    }
    ,computed: {
      reordenar_etapas() {
        let etapas = [];

        for(let i=0; i<this.etapas.length; i++) {
          if (this.etapas[i].tipo_usuario == this.etapa_nueva.tipo_usuario) {
            etapas.push(this.etapas[i]);
          }
        }

        etapas.sort((a,b) => {
          return a.posicion < b.posicion ? -1 : 1;
        })

        this.etapa_nueva.posicion = etapas[etapas.length-1].posicion;

        return etapas;
      }
    }
  }
</script>